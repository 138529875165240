import './stage.scss';
import Swiper from 'swiper';
import { A11y, Navigation, Pagination, Keyboard } from 'swiper/modules';

class StageSlider {
    constructor (element) {
        this.$slider = element;
    }

    initialize () {
        this.initSlider();
    }

    initSlider () {
        this.$slider = new Swiper(this.$slider, {
            modules: [A11y, Navigation, Pagination, Keyboard],
            a11y: true,
            lazyPreloadPrevNext: true,
            keyboard: true,
            spaceBetween: 20,
            speed: 500,
            allowTouchMove: true,
            navigation: {
                nextEl: '.stage__arrow--next',
                prevEl: '.stage__arrow--prev'
            },
            pagination: {
                el: '.stage__pagination',
                type: 'fraction',
                renderFraction: function (currentClass, totalClass) {
                    return '<span class="' + currentClass + '"></span>' + ' <span class="separator"></span> ' + '<span class="' + totalClass + '"></span>';
                }
            }
        });
    }
}

export { StageSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$roots = $context.querySelectorAll('[data-stage="slider"]');
        if ($$roots.length > 0) {
            $$roots.forEach($root => {
                const $rootAPI = new StageSlider($root);
                $rootAPI.initialize();
            });
        }
    }
});
